<style scoped>
  .v-text-field > .v-input__control > .v-input__slot > .v-text-field__slot {
      height: 20px !important;
  }
  /* .v-input__append-inner .v-input__icon>.v-icon{
    margin-top: 0px !important;
  } */
</style>
<style lang="scss">
  .pl--15{
    padding-left: 15px;
  }
  .td-params{
    width: 20% !important;
  }
  .td-created{
    width: 15%;
    text-align: center !important;
  }
  .c-input--search .v-input__slot {
      padding-top: 0px !important;
  }
  .pl-48{
    padding-left: 48px !important;
  }
  .bg-modal{
    background-color: white !important;
      &__flex{
        display: flex;
        flex-direction: column;
        align-content: flex-start;
        align-items: flex-start;
      }
  }
  .c-input--search .v-input__slot {
      border: 1px solid #80808087;
      padding: 0.25rem 0.5rem;
      border-radius: 30px;
      overflow: hidden;
      height: 25px;
  }
  .c-input--search .theme--light.v-label {
      color: gray !important;
      font-size: 14px !important;
  }
  .text-info{
    padding-left: 25px;
    margin-bottom: 8px !important;
    border-bottom: 1px solid #ADADAD9e !important;
  }
  #app .v-stepper--vertical .v-stepper__content {
    border: none !important;
  }

  .pt-0{
    padding-top: 0px !important;
  }
  .pb-0{
    padding-bottom: 0px !important;
  }
  .v-dialog {
    border-radius: 20px !important;
  }
    /*::-webkit-scrollbar {display: none;}*/
    #app{
      .text-start{
        // font-size: 12px !important;
      }
      .v-main__wrap{
        // overflow: hidden;
      }
      .v-application .v-data-table {
          box-shadow: none!important;
        td{
          padding: 0.75rem 1rem;
        }
      }
      .v-stepper--vertical{
        overflow: visible;
      }
      .c-view-title{
        border-bottom: 0px solid #dcdee0 !important;
      }
    .v-card__text{
      padding: 1rem 1.3rem !important;
    }
    .v-application .green.darken-1 {
      background-color: #27BC22 !important;
      border-color: #27BC22 !important;
      color:white !important;
      }
      .v-application .red.darken-1 {
      background-color: red !important;
      border-color: red !important;
      color:white !important;
      }

    }
    .ensayo__text {
      margin-left: 0rem !important;
    }
    .v-toolbar__content{
      padding-left: 0px !important;
    }
  .ml22{
    margin-left: 22px !important;
  }
  .theme--light.v-sheet {
      color: black !important;
  }
  .mt26{
    margin-top: 26px !important;
  }
  #app{
      .c-view-title-text {
        margin: initial;
      }
      .c-view-title{
        padding: 0 1.25rem;
      }
      .c-input{
        &--search{
          &.v-text-field .v-input__control{
            max-width: 250px;
            margin: auto;
            margin-right: 0;
          }
        }
      }
      .content-head {
        margin-bottom: 15px;
      }
    .ensayos__creados{
      .v-data-table-header th{
        padding: 16px 20px !important;
      }
      .v-data-table td {
        padding: 14px 20px;
        border-bottom: 2px solid #CCCCCC;
      }
      &.v-data-table--fixed-header .v-data-footer {
        margin-right: 0;
        background-color: #F6F8FA;
      }
    }
  }

  .v-toolbar__content{
    padding: 0;
  }
  .c-testTaag{
    .v-data-table td{
      border-bottom: 0px solid #CCCCCC!important;
    }
  }
  .v-dialog__content{
    .v-card__text{
      white-space: pre-line;
      //max-width: 420px;
      margin: auto;
    }
  }
  .v-list-item__title {
    overflow: initial;
    text-overflow: initial;
    white-space: initial;
  }
  .v-list-item__icon {
    align-self: flex-start;
    margin: 6px 0;
  }
  .v-list-item__content{
    padding: 6px 0;
  }
  .v-list-item .v-list-item__title{
    line-height: 1.4;
  }
</style>
<template>
<!--eslint-disable-->
  <div>
      <div>
        <v-card class="mx-5 bg--light__gray">
          <v-toolbar
            class="c-view-title bg--light__gray"
          >
            <v-row class="align-items-flex-end">
              <!--<toogleNavMenu ref="toogleNavMenu" />-->
              <v-col class="col-auto mt-2 c-view-title-text">
                <p class="text-left bold mb-2 fs20">{{ $t('ensayos.ensayos_lab') }}</p>
                <p class="fs16 mb-0">{{ $t('ensayos.crear_edita') }}</p>
              </v-col>
              <v-spacer></v-spacer>
              <v-col cols="3" xs="6">
                <v-text-field
                  class="c-input c-input--search"
                  v-model="search"
                  append-icon="mdi-magnify"
                  :placeholder="$t('ensayos.buscar')"
                  dense
                ></v-text-field>
              </v-col>
            </v-row>
          </v-toolbar>
           <!--<v-card-title class="pb-0" >
           <v-icon>mdi-flask-outline</v-icon>
            <svg class="icon-svg" width="34px" height="34px" viewBox="0 0 34 34"
            style="--fillColor: #333; --bgColor: white;">
            <use xlink:href="img/iconsSVG.svg#iconEnsayoHome"></use>
            </svg>
          </v-card-title>-->

          <v-card-text class="">
            <!--modal parametro y matrices-->
            <!-- <div> -->
              <div class="bg--normal__white border--radius__10 p__content-ensayos">
                <div class="content-head">
                  <div class="d-flex">
                    <span class="bold fs16">{{ $t('ensayos.resumen') }}</span>
                    <!-- <span class="fs16 bold bold--blue ensayo__num">{{ dataDT.length }}</span> -->
                  </div>
                  <div class="d-flex">
                    <v-btn
                      class="c-btn"
                      color="#007AFF"
                      outlined
                      @click="showmodalKits()"
                    >
                    {{ $t('ensayos.activite') }}
                    </v-btn>
                    <v-btn
                      class="ml-5 c-btn c-btn--blue bold"
                      outlined
                      @click="showForm()"
                    >
                      <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                      stroke="currentColor" stroke-width="2" stroke-linecap="round"
                      stroke-linejoin="round" class="mr-2 feather feather-plus-circle">
                        <circle cx="12" cy="12" r="10"></circle>
                        <line x1="12" y1="8" x2="12" y2="16"></line>
                        <line x1="8" y1="12" x2="16" y2="12"></line>
                      </svg>
                      {{ $t('ensayos.crear_nuevo_ensayo') }}
                    </v-btn>
                  </div>
                </div>
                <v-data-table
                :search="search"
                :loading="loading"
                :headers="header"
                :items="dataDT"
                class="ensayos__creados"
                fixed-header dense
                :noResultsText="$t('ensayos.ningun_registro')"
                 :footer-props="{itemsPerPageText: textofooter}"
                >
                  
                  <template v-slot:item.activo="{ item }">
                    <div v-if="item.activo == 0">
                      <span>
                        {{ $t('ensayos.inactivo') }}
                      </span>
                    </div>
                    <div v-else>
                      <span class="text-active-2">
                        {{ $t('ensayos.activo') }}
                      </span>
                    </div>
                  </template>
                  <template v-slot:item.param="{ item }">
                    <div class="text-center">
                      <a
                        class="text-active w-100 text-center"
                        @click="modalparam(item)"
                      >
                        {{ $t('ensayos.detalle') }}
                        <svg class="ml-2" width="5.629" height="9.758" viewBox="0 0 5.629 9.758">
                          <path id="chevron-right" d="M9,13.637l3.818-3.818L9,6" transform="translate(-7.939 -4.939)" fill="none" stroke="#007aff" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
                        </svg>
                      </a>
                    </div>
                        
                  </template>
                  <template v-slot:item.actions="{ item }" >
                    <div class="d-flex justify-content-evenly px-5 c-option-icons" v-if="item.ensayotaag === 0">
                      <img class="pointer c-option-icons__icon" @click="editEnsayo(item)" src="/img/editar.svg" :title="$t('ensayos.editar_ensayo')">
                      <img v-if="item.externo==0" class="pointer c-option-icons__icon" @click="confirmDeleteEnsayo(item)" src="/img/eliminar.svg" :title="$t('ensayos.eliminar_ensayo')">
                    </div>
                    <div v-else>
                      <v-switch
                      v-model="item.activo"
                      :label="$t('ensayos.activo')"
                      @change="activeSteriplex(item)"
                    ></v-switch>
                    </div>
                  </template>
                </v-data-table>
              </div>
            <!-- </div> -->
          </v-card-text>
        </v-card>
        <!-- modal -->
          <v-dialog v-model="dialog" width="650">
            <v-card >
              <v-card-text v-if="infomodal" style="padding: 2rem 2rem 0rem 2rem !important;">
                <p class="text-left fs20 bold">{{ $t('ensayos.param_matrices') }}</p>
                  <v-simple-table>
                    <template v-slot:default>
                      <thead>
                      </thead>
                      <tbody>
                        <tr>
                          <td  class="w30 contentparamametros text-left bold fs16">{{$t('ensayos.Testname')}}</td>
                          <td  class="w70 contentparamametros text-left fs16">{{infomodal.ensa_informe}}</td>
                        </tr>
                        <tr>
                          <td  class="w30 contentparamametros text-left bold fs16">{{$t('ensayos.Testcategory')}}</td>
                          <td  class="w70 contentparamametros text-left fs16">{{infomodal.tipo}}</td>
                        </tr>
                        <tr>
                          <td  class="w30 contentparamametros text-left bold fs16">{{$t('ensayos.Status')}}</td>
                          <td  class="w70 contentparamametros text-left fs16">
                           <div>
                            <div  v-if="infomodal.activo == 0">
                              <span >
                                {{ $t('ensayos.inactivo') }}
                              </span>
                            </div>
                            <div v-else>
                                <span class="text-active">
                                    {{ $t('ensayos.activo') }}
                                </span>
                            </div>
                          </div>
                          </td>
                        </tr>
                        <tr>
                          <td  class="w30 contentparamametros text-left bold fs16">{{ $t('ensayos.matrices') }}</td>
                          <td  class="w70 contentparamametros c-matrices-list text-left fs16">
                           <div class="d-flex">
                            <span class="contents" v-for="(item, i) in infomodal.matriz" :key="item" v-html="formarListados( {matriz:infomodal.matriz, item, i, funcion:getLang } )">
                            </span>
                          </div>
                          </td>
                        </tr>
                        <tr>
                          <td  class="w30 contentparamametros text-left bold fs16">{{ $t('ensayos.param') }}</td>
                          <td  class="w70 contentparamametros c-parametros-list text-left fs16">
                           <div class="d-flex">
                            <span class="contents" v-for="(item, i) in infomodal.parametro" :key="item" v-html="formarListados( {matriz:infomodal.parametro, item, i} )">
                            </span>
                          </div>
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
              </v-card-text>
              <v-card-actions class="b-top mx-8 action-padding-btn">
                <v-spacer></v-spacer>
                 <v-btn
                    class="c-btn"
                    color="primary"
                    outlined
                    @click="dialog = false"
                  >
                    {{ $t('ensayos.cancelar') }}
                </v-btn>
                <v-btn
                    class="c-btn"
                    color="primary"
                    @click="editEnsayo(infomodal)"
                  >
                    <!-- <svg class="mr-2" width="17.5" height="17.5" viewBox="0 0 17.5 17.5">
                      <g id="edit" transform="translate(-1.25 -1.129)">
                        <path id="Trazado_40996" data-name="Trazado 40996" d="M9.269,4H3.615A1.615,1.615,0,0,0,2,5.615V16.922a1.615,1.615,0,0,0,1.615,1.615H14.922a1.615,1.615,0,0,0,1.615-1.615V11.269" transform="translate(0 -0.658)" fill="none" stroke="#007aff" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
                        <path id="Trazado_40997" data-name="Trazado 40997" d="M15.241,2.307A1.463,1.463,0,0,1,17.31,4.376l-6.551,6.551L8,11.617l.69-2.758Z" transform="translate(0.262)" fill="none" stroke="#007aff" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
                      </g>
                    </svg> -->
                    {{ $t('ensayos.editar') }}
                  </v-btn>
                <!-- <v-btn
                    class="ml-5 c-btn c-btn--blue bold"
                    outlined
                    @click="dialog = false"
                  >
                    {{ $t('solicitudes.guardar') }}
                </v-btn> -->
               
              </v-card-actions>
            </v-card>
          </v-dialog>

        <!-- /modal -->

        <!-- CONFIRMACION DE ELIMINAR -->
        <confirm :Father="this" ref="confirm"/>
        <!--  -->
        <!-- modal kits -->
        <modalkitsTaag ref="modalKits"></modalkitsTaag>
      </div>
  </div>
</template>

<script>
/*eslint-disable*/
import _ from 'lodash';
import Service from '../../services/apis';
import i18n from '../../plugins/il8n';

export default {
  props: {
    setSee: Function,
  },
  components: {
    confirm: () => import('./confirmDeleteEnsayo.vue'),
    modalkitsTaag: () => import('./modalKitstaag.vue'),
    // toogleNavMenu: () => import('../toogleNavMenu'),
  },
  data: () => ({
    switch1: false,
    infomodal: {},
    dialog: false,
    loading: true,
    navOpen: false,
    // TABLA
    search: '',
    items: [],
    ensayosExt:[],
    arraytipoensayo: [
      {
        id: 1,
        nombre: 'Patógeno',
      },
      {
        id: 2,
        nombre: 'Indicador',
      },
      {
        id: 3,
        nombre: 'Deteriorante',
      },
    ],
    itemData: null,
    loadEnsayo: false,
  }),
  computed: {
    dataDT() {
      const group = _.groupBy(this.items, 'grupo_ensayo_id');
      const data = [];
      Object.keys(group).map((i) => {
        // // console.log('group', group);
        const d = {
          matrices: [],
          parametros: [],
          ideasy: [],
        };
        // // console.log(group[i])
        // OBTIENE LAS MATRICES ID
        let testActive = 0;

        group[i].map((D) => { 
          // // console.log({D})
          if(D.ensayos_easypcrs[0].activo != 0){
            testActive = 1;
          }
          d.parametros = D.patogeno_new_has_ensayos.map((pat) => pat.patogeno_new.nombre);

          if(D.ensayos_easypcrs[0].activo == 1){
            d.matrices.push(D.matrix.matriz);
          }
        
          d.ideasy.push(D.id);
          return true;
        });
        d.matrices = _.uniq(d.matrices);
        d.parametros = _.uniq(d.parametros);
        const clasif = this.arraytipoensayo.filter((tipo) => tipo.id === group[i][0].clasificacion);
        // // console.log(group[i])
        // // console.log(d.matrices)
        if (group[i][0].ensayos_easypcrs[0].ensayo_lts === 0) {
          data.push({
            tipo: group[i][0].tipos_de_ensayo.descripcion_tipo_de_ensayo,
            cod_ensayo: group[i][0].cod_ensa,
            parametro: d.parametros,
            // activo: group[i][0].ensayos_easypcrs[0].activo,
            activo: testActive,
            ensa_informe: group[i][0].ensayos_easypcrs[0].name_ensayo,
            matriz: d.matrices,
            grupo_ensayo_id: group[i][0].grupo_ensayo_id,
            create_at: group[i][0].ensayos_easypcrs[0].create_at,
            clasificacion: (clasif.length > 0) ? clasif[0].nombre : '',
            ensayotaag: group[i][0].steriplex,
            grupo_id: d.ideasy,
            externo:0
          });
        }
        return true;
      });
      /// se arma el objeto de arreglos para mostrar los ensayos externos
      let grupoExternos = [];
      this.ensayosExt.forEach(element => {
        grupoExternos.push(element.ensayo);
      });
      const groupexternos = _.groupBy(grupoExternos, 'grupo_ensayo_id');
      Object.keys(groupexternos).map((i) => {
        // // console.log({groupexternos});
        const d = {
          matrices: [],
          parametros: [],
          ideasy: [],
        };
        // OBTIENE LAS MATRICES ID
        groupexternos[i].map((D) => {
          d.parametros = D.patogeno_new_has_ensayos.map((pat) => pat.patogeno_new.nombre);
          d.matrices.push(D.matrix.matriz);
          d.ideasy.push(D.id);
          return true;
        });
        // UNIQUE
        d.matrices = _.uniq(d.matrices);
        d.parametros = _.uniq(d.parametros);
        const clasif = this.arraytipoensayo.filter((tipo) => tipo.id === groupexternos[i][0].clasificacion);
        // if (groupexternos[i][0].ensayos_easypcrs[0].ensayo_lts === 0) {
          data.push({
            tipo: groupexternos[i][0].tipos_de_ensayo.descripcion_tipo_de_ensayo,
            cod_ensayo: groupexternos[i][0].cod_ensa,
            parametro: d.parametros,
            activo: 1,//groupexternos[i][0].ensayos_easypcrs[0].activo,
            ensa_informe: groupexternos[i][0].ensa_informe,
            matriz: d.matrices,
            grupo_ensayo_id: groupexternos[i][0].grupo_ensayo_id,
            create_at: null,//groupexternos[i][0].ensayos_easypcrs[0].create_at,
            clasificacion: (clasif.length > 0) ? clasif[0].nombre : '',
            ensayotaag: groupexternos[i][0].steriplex,
            grupo_id: d.ideasy,
            externo:1
          });
        // }
        return true;
      });
      //////// fin 
      // // console.log({groupexternos});
      // console.log('data', data);
      return data;
    },
    textofooter(){
      const textofooter = i18n.t('ensayos.Rowsperpage');
      return textofooter;
    },
    header() {
      const head = [
        { text: i18n.t('ensayos.nombre'),
          class: 'td-name',
          value: 'ensa_informe',
          sortable: true,
        },
        { text: i18n.t('ensayos.tipo'), 
          class: 'td-type', 
          value: 'tipo', 
          sortable: false, 
        },
        { text: i18n.t('ensayos.creado'), 
          class: 'td-created', 
          value: 'create_at', 
          sortable: false, 
          align: 'center',
        },
        { text: i18n.t('ensayos.param_mtz'), 
          class: 'td-params', 
          value: 'param', 
          sortable: false, 
        },
        { text: i18n.t('ensayos.Status'),
          class: 'td-estado', 
          value: 'activo', 
          sortable: false,
          align: 'center',
        },
        // { text: i18n.t('ensayos.clasif'), class: 'td-type', value: 'clasificacion' },
        { text: i18n.t('ensayos.option'),
          class: 'td-actions', 
          value: 'actions', 
          sortable: false, 
          align: 'center',
        },
      ];
      return head;
    },
  },
  methods: {
    getLang(text) {
      return i18n.t(`matrices.${text}`);
    },
    mantenedorCampos() {
      const linkslab = `${process.env.VUE_APP_TXA}Campos`;
      window.open(linkslab, '_blank');
    },
    modalparam(item) {
      // console.log({item});
      this.dialog = true;
      this.infomodal = item;
     
    },
    async activeSteriplex(item) {
      // // console.log(item);
      const activarSteriplex = await Service.apiToken('POST', 'activar-steriplex', this.$ls.storage.token, item);
      // // console.log(activarSteriplex);
    },
    showForm() {
      this.setSee(false, null, true);
    },
    editEnsayo(item) {
      // console.log({editEnsayoitem:item});
      this.loadEnsayo = true;
      this.setSee(false, item, false);
    },
    confirmDeleteEnsayo(item) {
      this.itemData = item;
      this.$refs.confirm.open(item);
    },
    async deleteEnsayo(item) {
      if (this.itemData) {
        const deletedTest = await Service.apiToken('POST', 'delete-test', this.$ls.storage.token, item);
        if (deletedTest) {
          this.init();
        }
        this.itemData = null;
      } else {

        // console.warn('No hay un elemento a eliminar');
      }
    },
    async init() {
      // // console.log('init');
      const response = await Service.apiToken('POST', 'get-ensayos', this.$ls.storage.token, { data: 1 });
      if (!response.error) {
        this.items = response.response;
        this.ensayosExt = response.dataExterno;
      }
      this.loading = false;
    },
    toogleNav() {
      if (this.navOpen === true) {
        this.navOpen = false;
        document.querySelector('.v-navigation-drawer').classList.add('v-navigation-drawer--open');
        document.querySelector('.v-navigation-drawer').classList.remove('v-navigation-drawer--close');
      } else {
        this.navOpen = true;
        document.querySelector('.v-navigation-drawer').classList.add('v-navigation-drawer--close');
        document.querySelector('.v-navigation-drawer').classList.remove('v-navigation-drawer--open');
      }
    },
    getparam(item) {
      let param = item.parametro;
      if (param.length > 4) {
        param = param.slice(0, 3);
        param = `${param.join()} + ${item.parametro.length - 3}`;
      } else {
        param = param.join();
      }
      return param;
    },
    formarListados( {matriz, item, i, funcion } ) {
      console.log({matriz, item, i});
      if( matriz.length - 1 > i ){
        return (!!funcion ? funcion(item) : item) + ',&nbsp;';
      }
      else{
        return (!!funcion ? funcion(item) : item) + '.'
      }
    },
    showmodalKits() {
      this.$refs.modalKits.openmodal();
    },
  },
  async created() {
    this.init();
  },
};
</script>
